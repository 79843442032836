<template>
  <div>
    <!-- <a-card title="入库任务"> -->
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
        <a-input-search v-model="searchForm.search" placeholder="单号" allowClear @search="search" />
      </a-col>
    </a-row>

    <a-row style="margin-top: 12px">
      <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading"
        :pagination="pagination" @change="tableChange">

        <template slot="details" slot-scope="text, record">
          <a-tooltip :title="generateDetails(record)">
            <span
              style="display: inline-block; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ generateDetails(record).slice(0, 30) }}
            </span>
          </a-tooltip>
        </template>

        <div slot="action" slot-scope="value, item">
          <a-button-group size="small">
            <a-button size="small" @click="detial(item)">详情</a-button>
            <a-button type="primary" size="small" :disabled="item.is_completed || item.is_void"
              @click="toStockIn(item)">
              入库
            </a-button>
          </a-button-group>
        </div>
      </a-table>
    </a-row>
    <!-- </a-card> -->
  </div>
</template>

<script>
import { stockInOrdersList, stockInOrdersVoid } from "@/api/warehousem";

export default {
  name: "SaleRecord",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "单号",
          dataIndex: "number",
          sorter: true,
        },
        {
          title: "详情",
          dataIndex: "details",
          width: '250px',
          scopedSlots: { customRender: 'details' },
        },
        {
          title: "采购单号",
          dataIndex: "purchase_order_number",
          sorter: true,
        },
        {
          title: "处理人",
          dataIndex: "creator_name",
          sorter: true,
        },
        {
          title: "期望到达时间",
          dataIndex: "eta",
          customRender: (value, record) => {
            const today = new Date().toISOString().split('T')[0];
            const isToday = value === today;
            const isPast = value < today;

            const className = isToday ? 'is-today' : isPast ? 'is-past' : '';
            return {
              children: <div class={className}>{value}</div>,
              attrs: {},
            };
          },
        },
        {
          title: "处理日期",
          dataIndex: "create_time",
          width: 170,
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: 147,
        },
      ],
      searchForm: { page: 1, is_void: 0, is_completed: false, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      stockInOrdersList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    toStockIn(item) {
      this.$router.push({ path: "/warehousem/inStock_create", query: { id: item.id } });
    },
    detial(item) {
      this.$router.push({ path: "/warehousem/inStock_detail", query: { id: item.id } });
    },
    voidItem(item) {
      stockInOrdersVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },

    generateDetails(item) {
      const results = item.stock_in_goods_items.map(innerItem => {
        const quantityUnit = [
          innerItem['stock_in_quantity'],
          innerItem['unit_name']
        ].filter(value => value != null).join('');  // 直接连接，不使用下划线

        const parts = [
          innerItem['goods_number'],
          innerItem['goods_name'],
          quantityUnit  // 把合并后的数量和单位作为一个部分
        ].filter(value => value != null);  // 继续过滤掉null和undefined

        return parts.join('_');  // 使用下划线连接字符串
      });
      return results.join(', ');
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.is-today {
  background-color: yellow;
}

.is-past {
  background-color: red;
}
</style>
